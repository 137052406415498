import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import { Icon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  HStack,
  InputGroup,
  Stack,
  Text,
  Avatar,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { FiHelpCircle, FiSettings } from "react-icons/fi";
import { Logo } from "./Logo";
import { NavButton } from "./NavButton";
import { UserProfile } from "./UserProfile";
import { useStytchUser } from "@stytch/react";
import { useStytch } from "@stytch/react";
export function Sidebar(props) {
  const { routes } = props;

  const stytchUser = useStytchUser();
  const stytchClient = useStytch();
  const [apiUser, setApiUser] = React.useState(null); 
  const { colorMode, toggleColorMode } = useColorMode();

  if (stytchUser.user === null) {
    window.location.href = "/#/auth/sigh-in/login";
  }

  // Grab API user data
  fetch("/api/v1/user/user/" + stytchUser.user.user_id).then((response) => {
    response.json().then((data) => {
      setApiUser(data);
    });
  });

  const getButtons = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/safari") {
        if (!prop.display) {
          return null;
        }
        return (
          <NavLink to={prop.layout + prop.path} key={key}>
            <Button variant="ghost" justifyContent="start" value={prop.name}>
              <HStack spacing="3">
                <Icon as={prop.icon} boxSize="6" color="subtle" />
                <Text>{prop.name}</Text>
              </HStack>
            </Button>
          </NavLink>
        );
      }
      if (prop.collapse) {
        return getButtons(prop.items);
      }
      if (prop.category) {
        return getButtons(prop.items);
      } else {
        return null;
      }
    });
  };

  return (
    <Flex as="section" bg="bg-canvas">
      <Flex
        flex="1"
        bg="bg-surface"
        boxShadow={useColorModeValue("md", "sm-dark")}
        maxW={{
          base: "full",
          sm: "xs",
        }}
        py={{
          base: "6",
          sm: "8",
        }}
        px={{
          base: "4",
          sm: "6",
        }}
        h="100%"
      >
        <Stack h="100%" justify="space-between" spacing="1">
          <Stack
            spacing={{
              base: "5",
              sm: "6",
            }}
            shouldWrapChildren
          >
            <InputGroup></InputGroup>
            <Stack spacing="1">{getButtons(routes)}</Stack>
          </Stack>
          <Stack
            spacing={{
              base: "5",
              sm: "6",
            }}
          >
            <Stack spacing="1">
              <NavButton label="Help" icon={FiHelpCircle} />
              <NavButton label="Settings" icon={FiSettings} />
              <Button label="Dark Mode" onClick={toggleColorMode}>
                Switch Themes
              </Button>
              <Button
                label="Logout"
                icon={FiSettings}
                onClick={() => {
                  stytchClient.session.revoke();
                }}
              >
                Logout
              </Button>
            </Stack>

            <Divider />
            <UserProfile
              name={
                stytchUser.user.name.first_name +
                " " +
                stytchUser.user.name.last_name
              }
              image={stytchUser.user.profile_picture_url}
              email={stytchUser.user.emails[0].email}
            />
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
}
