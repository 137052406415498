import React, {useEffect} from "react";
import { useStytch } from "@stytch/react";
import { useStytchSession } from "@stytch/react";

export default function Authenticate() {
  const stytchClient = useStytch();
  const { session } = useStytchSession();

  const [apiUser, setApiUser] = React.useState(null);

  useEffect(() => {
    if (session) {
      window.location.href = '/#/safari/projects/';

      // Fetch API user data
      fetch("/api/v1/user/user/" + session.user.user_id).then((response) => {
        response.json().then((data) => {
          setApiUser(data);
        });
      });

      if (apiUser.status === 'active') {
        window.location.href = '/#/safari/projects/';
      }

    } else {
      // Redirect to login
      window.location.href = "/#/auth/sign-in/login";
    }
  }, [stytchClient, session]);

  return <div>Awaiting User Validation</div>;
};