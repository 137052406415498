import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdHome, MdDashboard } from "react-icons/md";

// App Imports
import Project from "views/app/projects/Project.jsx";
import SafariProjects from "views/app/projects/SafariProjects.jsx";

// Auth Imports
import ForgotPassword from "views/auth/forgotPassword/ForgotPasswordDefault.jsx";
import SignInDefault from "views/auth/signIn/SignInDefault.jsx";
import SignUpDefault from "views/auth/signUp/SignUpDefault.jsx";
import VerificationDefault from "views/auth/verification/VerificationDefault.jsx";

const routes = [
  {
    name: "App",
    path: "/app",
    collapse: true,
    icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
    items: [
      {
        name: "Dashboard",
        path: "/app/dashboard",
        collapse: true,
        items: [
          {
            name: "Project",
            layout: "/safari",
            path: "/dashboard/project/:id",
            component: Project,
            exact: false,
          },
          {
            name: "Safari Projects",
            layout: "/safari",
            path: "/dashboard/safari-projects",
            component: SafariProjects,
            exact: false,
          }
        ]
      },
    ],
  },
  {
    name: "Authentication",
    path: "/auth",
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: "Sign In",
        path: "/sign-in",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/sign-in/login",
            icon: (
              <Icon as={MdHome} width="16px" height="16px" color="inherit" />
            ),
            component: SignInDefault,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: "Sign Up",
        path: "/sign-up",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/sign-up/register",
            icon: (
              <Icon as={MdHome} width="16px" height="16px" color="inherit" />
            ),
            component: SignUpDefault,
          },
        ],
      },
      // --- Pending ---
      {
        name: "Pending",
        path: "/pending",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/pending",
            icon: (
              <Icon as={MdHome} width="16px" height="16px" color="inherit" />
            ),
            component: VerificationDefault,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: "Forgot Password",
        path: "/forgot-password",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/forgot-password/form",
            icon: (
              <Icon as={MdHome} width="16px" height="16px" color="inherit" />
            ),
            component: ForgotPassword,
          },
        ],
      },
    ],
  },
];

export default routes;
