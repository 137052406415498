import React from "react";
import { NavLink, Redirect } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/types/Default";
// Assets
import illustration from "assets/img/auth/sa.jpg";
import Login from "components/authentification/Login";
import MicrosoftOAuth from "components/authentification/MicrosoftOAuth";

import { Products } from "@stytch/vanilla-js";
import { StytchLogin } from "@stytch/react";
import { OAuthProvidersTypes, Stytch } from "@stytch/stytch-react";
import { useStytchSession, useStytch } from "@stytch/react";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("gray.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const userAlreadyRegisteredColor = useColorModeValue("brand.500", "red");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handlePasswordShowClick = () => setShow(!show);
  // Stytch state
  const stytchClient = useStytch();
  const session = useStytchSession();

  // Email, Password, Visibility
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [invalidPassword, setInvalidPassword] = React.useState(false);

  // Check if session is valid
  React.useEffect(() => {
    console.log(session);
    if (session.session) {
      // Fetch user within api
      const api_url = "/ape/api/v1/user/users/" + session.session.user_id;
      fetch(api_url).then((response) => {
        response.json().then((data) => {
          // Check if user status is pending or active
          if (data.status === "pending") {
            // Redirect to pending page
            window.location.href = "/safari-ape/#/auth/sign-in/pending";
          } else if (data.status === "active") {
            // Redirect to home
            window.location.href = "/safari-ape/#/safari/projects";
          }
        });
      });
      // Navigate to home
      window.location.href = "/safari-ape/#/safari/projects";
    } else {
      const token = new URLSearchParams(window.location.search).get("token");
      stytchClient.oauth.authenticate(token, {
        session_duration_minutes: 60,
      });
    }
  }, [stytchClient, session]);

  const config = {
    products: ["passwords", "oauth"],
    passwordOptions: {
      loginExpirationMinutes: 30,
    },
    oauthOptions: {
      providers: [{ type: "microsoft" }],
    },
  };

  const handleClick = async (e) => {
    e.preventDefault();
    stytchClient.passwords
      .authenticate({
        email: email,
        password: password,
        session_duration_minutes: 60,
      })
      .then((response) => {
        if (session.session) {
          window.location.href = "/#/safari";
        }
      })
      .catch((error) => {
        if (
          error.message.includes("email_not_found") ||
          error.message.includes("invalid_email")
        ) {
          setInvalidEmail(true);
          setInvalidPassword(false);
        }
        if (error.message.includes("unauthorized_credentials")) {
          setInvalidPassword(true);
          setInvalidEmail(false);
        }
      });
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <StytchLogin config={config} />
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
